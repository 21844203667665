import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

type Props = {}

const Header = (props: Props) => {

	const data = useStaticQuery(graphql`
	{
		contentYaml(
		  fields: {fileName: {eq: "page-sections-header"}, parentFolder: {eq: "content"}}
		) {
		  title
		  sub_title
		  show_header
		  title_bg {
			childImageSharp {
			  gatsbyImageData(width: 1270)
			}
		  }
		}
	  }
	`)

	const { sub_title, title, title_bg, show_header } = data.contentYaml;
	  const titleBg = getSrc(title_bg);
	 
  return (<>
    <div className={`section padding-top-from-nav background-black ${show_header ? `with-header` : `without-header`}`}>
		{show_header && <div className="container">
			<div className="twelve columns">
				<div className="home-text-freelance fade-elements">
					<h1>{sub_title}<br /><span style={{backgroundImage:`url(${titleBg})`}}>{title}</span></h1>
				</div>	
			</div>
		</div>}
	</div>
</>)
}

export default Header