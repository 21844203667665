import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

type Props = {}
type SocialMedia = {
	color: string;
	name: string;
	url: string;
}

const Footer = (props: Props) => {

	const data = useStaticQuery(graphql`
	{
		contentYaml(
		  fields: {fileName: {eq: "page-sections-footer"}, parentFolder: {eq: "content"}}
		) {
		  email
		  phone_number
		  social_media {
			color
			name
			url
		  }
		}
	  }
	  
	`)

	const { email, phone_number, social_media } = data.contentYaml;

  return (<>
    <div className="section padding-top-bottom background-black">
		<div className="container">
			<div className="six columns footer" data-scroll-reveal="enter bottom move 60px over 0.9s after 0.1s">
				<p>Call us at <a href={`tel:${phone_number}`}>{phone_number}</a><br />or say hello at <a href={`mailto:${email}`}>{email}</a></p>
			</div>
			<div className="six columns" data-scroll-reveal="enter bottom move 60px over 0.9s after 0.1s">
				{social_media && <ul className="footer-social">
					{social_media.map(({ name, url, color }:SocialMedia) => (<li key={name}>
						<a href={url} target="_blank" rel="noopener noreferrer">
							<span style={{ backgroundColor: color }}></span>
							{name}
						</a>
					</li>))}
				</ul>}
			</div>
		</div>
	</div>

</>)
}

export default Footer