import React, { useCallback, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { LiteYoutubeEmbed } from 'react-lite-yt-embed';
import Vimeo from '@u-wave/react-vimeo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

type Props = {
  visible: boolean;
  handleClose: Function;
  frontmatter: Details;
  description: string;
};

type Details = {
  category: string;
  tags: string[];
  thumbnail: any;
  title: string;
  video_id: string;
  video_source: string;
  type: string;
  url: string;
};
type SocialMedia = {
  color: string;
  name: string;
  url: string;
};

const PortfolioSingle = ({ visible, handleClose, frontmatter, description }: Props) => {

  const data = useStaticQuery(graphql`
    {
      contentYaml(
        fields: {
          fileName: { eq: "page-sections-footer" }
          parentFolder: { eq: "content" }
        }
      ) {
        social_media {
          color
          name
          url
        }
      }
    }
  `);

  const { social_media } = data.contentYaml;

  const Image = () => {
    return <a href={frontmatter.url} target="_blank">
      <GatsbyImage image={getImage(frontmatter.thumbnail)} alt={frontmatter.title} />
      </a>
  };

  const YoutubeEmbed = () => {
    return (
      <LiteYoutubeEmbed 
      id={frontmatter.video_id}
      mute={false}
      />
    );
  };

  const VimeoEmbed = () => {
    return (
      <Vimeo
      video={frontmatter.video_id}
      autoplay={false}
      responsive
      />
     
    );
  };

  const Video = () => {
    switch (frontmatter.video_source) {
      case "youtube":
        return <YoutubeEmbed />;
      case "vimeo":
        return <VimeoEmbed />;
      default:
        return <>{frontmatter.title}</>;
    }
  };

  const Type = useCallback(() => {
    switch (frontmatter.type) {
      case "image":
        return <Image />;
      case "video":
        return <Video />;
      default:
        return <>{frontmatter.title}</>;
    }
  },[visible]);

  useEffect(() => {

  },[])
  return (
    <>
      <div
        className={`cd-nav-container ${visible ? `is-visible` : ``}`}
        id="cd-nav"
      >
        <header>
          <span onClick={() => handleClose()} className="cd-close-nav">
            Close
          </span>
        </header>

        <div className="cd-nav portfolio-single">
        <div className="video-container">
          <Type />
          </div>
          <div className="portfolio-single-desc" dangerouslySetInnerHTML={{__html: description}} />
        </div>

        {social_media && (
          <ul className="nav-social">
            {social_media.map(({ name, url, color }: SocialMedia) => (
              <li key={name}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <span style={{ backgroundColor: color }}></span>
                  {name}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="cd-overlay"></div>
    </>
  );
};

export default PortfolioSingle;
