import React, { useState, useEffect, useCallback } from 'react'
import { Link } from "gatsby";
import Menu from "../Menu/Menu";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
type Props = {
  currentPage: string;
}

const Nav = (props: Props) => {

    const [showMenu, setShowMenu] = useState(false);
    const [shrinked, setShrinked] = useState(false);

    const data = useStaticQuery(graphql`
	{
		contentYaml(
		  fields: {fileName: {eq: "page-sections-header"}, parentFolder: {eq: "content"}}
		) {
		  logo {
        childImageSharp {
          gatsbyImageData(width: 300)
        }
      }
		}
	  }
	`)

	const { logo } = data.contentYaml;

  const handleScroll = useCallback(() => {
    if( window.pageYOffset > 50) {
      setShrinked(true);
    } else {
      setShrinked(false);
    }
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [handleScroll])

    return (<>
    <Menu currentPage={props.currentPage} showMenu={showMenu} setShowMenu={setShowMenu} />
        <nav id="menu-wrap" className={`menu-back cbp-af-header ${shrinked ? `cbp-af-header-shrink` : ``}`}>
        <div className="container">
          <div className="twelve columns">
            <div className="menu">
              <Link to={"/"}>
                <div className="logo">
                <GatsbyImage image={getImage(logo)} alt="logo" />
                </div>
              </Link>
              <span className="cd-nav-trigger" onClick={() => setShowMenu(true)}>
                Menu<span></span>
              </span>
            </div>
          </div>
        </div>
      </nav>
  </>)
}

export default Nav