import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

type Props = {
  showMenu: boolean;
  setShowMenu: Function;
  currentPage: string;
};
type SocialMedia = {
	color: string;
	name: string;
	url: string;
}
const Menu = ({ showMenu, setShowMenu, currentPage }: Props) => {

  const data = useStaticQuery(graphql`
	{
		contentYaml(
		  fields: {fileName: {eq: "page-sections-footer"}, parentFolder: {eq: "content"}}
		) {
		  social_media {
        color
        name
        url
		  }
		}

    metadata: contentYaml(
      fields: {fileName: {eq: "settings-site-metadata"}, parentFolder: {eq: "content"}}
    ) {
      linkedin_url
    }

	}
	  
	`)

	const { social_media } = data.contentYaml;
  const _currentPage = currentPage ?? 'index';

	const { linkedin_url } = data.metadata;

  return (
    <>
      <div className={`cd-nav-container ${showMenu ? `is-visible`: ``}`} id="cd-nav">
        <header>
          <span onClick={() => setShowMenu(false)} className="cd-close-nav">
            Close
          </span>
        </header>

        <div className="cd-nav">
          <nav className="menu menu--mohe">
            <Link className={`menu__item ${_currentPage==='index' ? `current-nav` : ``}`} to={"/"} onClick={() => setShowMenu(false)}>
              <span className="menu__item-name">Work</span>
              <span className="menu__item-label">Explore the portfolio</span>
            </Link>
            <a className={`menu__item ${_currentPage==='about' ? `current-nav` : ``}`} href={linkedin_url} target="_blank" onClick={() => setShowMenu(false)}>
              <span className="menu__item-name">About Me</span>
              <span className="menu__item-label">
                Read more about me
              </span>
              </a>
           
          </nav>
        </div>

        {social_media && <ul className="nav-social">
					{social_media.map(({ name, url, color }:SocialMedia) => (<li key={name}>
						<a href={url} target="_blank" rel="noopener noreferrer">
							<span style={{ backgroundColor: color }}></span>
							{name}
						</a>
					</li>))}
				</ul>}
      </div>

      <div className="cd-overlay"></div>

    </>
  );
};

export default Menu;
