import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby";
import PortfolioItem from './PortfolioItem'

type Props = {}
type Nodes = {
	fields: {
		fileName: string
	}
	frontmatter: { 
		category: string
        tags: string[]
        thumbnail: any
        title: string
        video_id: string
        video_source: string
		type: string
		url: string
	}
}

const Portfolio = (props: Props) => {

	const data = useStaticQuery(graphql`
    {
		portfolioPage: contentYaml(
			fields: {fileName: {eq: "page-sections-portfolio"}, parentFolder: {eq: "content"}}
		  ) {
			layout3columns
			layoutItemSpaces
			layoutColumnGapSize
			itemHoverEffect
		  }

		categories: contentYaml(
		  fields: {fileName: {eq: "settings-portfolio-categories"}, parentFolder: {eq: "content"}}
		) {
		  categories {
			name
		  }
		}

		portfolioItems: allMarkdownRemark(
			sort: {fields: frontmatter___priority, order: DESC}
		) {
		  nodes {
			frontmatter {
			  category
			  tags
			  title
			  video_id
			  video_source
			  type
			  url
			  thumbnail {
				childImageSharp {
				  gatsbyImageData(width: 610)
				}
			  }
			}
			fields {
			  fileName
			}
			html
		  }
		}
	  }
	  
  `);

  const { layout3columns, layoutItemSpaces, layoutColumnGapSize, itemHoverEffect } = data.portfolioPage;
  const { categories } = data.categories;
  const { nodes } = data.portfolioItems;

  const [filter, setFilter] = useState("*");

  const duration = 300

	const isVisible = (category:string) => {
		if( filter === "*" ) return true;
		if( filter === category ) return true;
		return false;
	};

  return (<>
    <div className="section padding-top-bottom background-black">
	
	<div className="container">
		<div className="twelve columns">
			<div id="portfolio-filter" className="portfolio-filter">
				<ul id="filter">
					<li><span className={filter==='*' ? `current` : ``} data-filter="*" title="" onClick={() => setFilter("*")}>all</span></li>
					{categories.map(({ name }: { name: string }) => (
						<li key={name}>
							<span onClick={() => setFilter(name)} className={`${filter===name ? `current` : ``}`} title={name}>{name}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	</div>
				
	<div id="projects-grid" className={`in-container have-space ${layout3columns ? `three-columns` : ``}`}>
	{nodes.map((node: Nodes, index: number ) => (
		<PortfolioItem key={index} 
		{...node} 
		index={index} 
		isVisible={isVisible(node.frontmatter.category)} 
		itemHoverEffect={itemHoverEffect} 
		layout3columns={layout3columns} 
		layoutItemSpaces={layoutItemSpaces} 
		layoutColumnGapSize={layoutColumnGapSize} 
		/>
	))}
	</div>
</div>

</>)
}

export default Portfolio