import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { CSSTransition } from "react-transition-group";
import PortfolioSingle from "./PortfolioSingle";
import Tippy from "@tippyjs/react";
import { followCursor } from "tippy.js";
import "tippy.js/dist/tippy.css"; // optionaloptional for styling

type Props = {
  layout3columns: boolean;
  layoutItemSpaces: boolean;
  layoutColumnGapSize: number;
  itemHoverEffect: string;
  isVisible: boolean;
  index: number;
  frontmatter: {
    category: string;
    tags: string[];
    thumbnail: any;
    title: string;
    video_id: string;
    video_source: string;
    type: string;
    url: string;
  };
  html: string;
  fields: {
    fileName: string;
  };
};

const PortfolioItem = ({
  layoutItemSpaces,
  layoutColumnGapSize,
  itemHoverEffect,
  isVisible,
  index,
  frontmatter,
  fields,
  html,
}: Props) => {
  const [showItem, setShowItem] = useState(false);
  const [visible, setVisible] = useState(false);

  const duration = 300;
  const handleClick = () => {
    setShowItem(true);
    setTimeout(() => setVisible(true));
  };
  const handleClose = () => {
    setVisible(false);
    setTimeout(() => setShowItem(false), 500);
  };

  return isVisible ? (
    <>
        <Tippy
          content={frontmatter.title}
          followCursor={true}
          plugins={[followCursor]}
        >
          <div
            key={fields.fileName}
            id={`portfolio-item-${index}`}
            className={`tipped grid-item ${frontmatter.category}`}
            style={{ padding: layoutItemSpaces ? `${layoutColumnGapSize}px` : `0px`}}
          >
            <div
              className={`cursor-pointer thumbnail-wrapper hover-${itemHoverEffect}`}
              onClick={handleClick}
            >
              <GatsbyImage
                image={getImage(frontmatter.thumbnail)}
                alt={frontmatter.title}
              />
            </div>
            {showItem && (
              <PortfolioSingle
                visible={visible}
                handleClose={handleClose}
                frontmatter={frontmatter}
                description={html}
              />
            )}
          </div>
        </Tippy>
    </>
  ) : ``;
};

export default PortfolioItem;
